import { API } from 'aws-amplify';
import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box,
  Card,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Typography,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField
} from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import Scrollbar from '../../Scrollbar';
import TrashIcon from '../../../icons/Trash';
import SearchIcon from '../../../icons/Search';
import ArrowRightIcon from '../../../icons/ArrowRight';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

const sortOptions = [
  {
    label: 'Host (A-Z)',
    value: 'hostname|asc'
  },
  {
    label: 'Host (Z-A)',
    value: 'hostname|desc'
  }
];
const apiName = 'surveyor-API';

function HostListTable() {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const [hosts, setHosts] = useState([]);

  const hostsPath = (user.role === 'rdpt_admin' ? '/admin/hosts' : '/hosts');
  const getHosts = useCallback(async () => {
    try {
      const response = await API.get(apiName, hostsPath);
      if (isMountedRef.current) {
        setHosts(response);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getHosts();
  }, []);

  const handleChange = async (ID, status) => {
    const h = [...hosts];
    if (status === 'active') {
      status = 'inactive';
    } else {
      status = 'active';
    }
    const path = '/host/status';
    const param = {
      body: {
        ID,
        status
      }
    };
    const response = await API.post(apiName, path, param);
    if (response) {
      h.forEach((host) => {
        if (host.ID === ID) {
          host.status = status;
          enqueueSnackbar(`${host.hostname} set to ${status}!`, {
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top'
            },
            autoHideDuration: 5000,
            variant: 'success'
          });
        }
      });
    }
    setHosts(h);
  };

  const handleDelete = async (ID) => {
    const path = '/host';
    const h = [...hosts];
    const param = {
      body: {
        ID
      }
    };
    const response = await API.del(apiName, path, param);
    let k = -1;
    if (response) {
      h.forEach((host, index) => {
        if (host.ID === ID) {
          k = index;
          enqueueSnackbar(`${host.hostname.toUpperCase()} Deleted!`, {
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top'
            },
            autoHideDuration: 5000,
            variant: 'success'
          });
        }
      });
    }
    delete h[k];
    console.log(h);
    setHosts(h);
    // document.location.reload();
  };
  return (
      <Card>
        <Box sx={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', m: -1, p: 2 }}>
          <Box sx={{ m: 1, maxWidth: '100%', width: 500 }}>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                )
              }}
              placeholder="Search hosts"
              variant="outlined"
              size="small"
            />
          </Box>
          <Box sx={{ m: 1, maxWidth: '100%', width: 240 }}>
            <TextField label="Sort By" name="sort" select SelectProps={{ native: true }} variant="outlined" size="small">
              {sortOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>
        </Box>
        <Scrollbar>
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Host
                  </TableCell>
                  <TableCell>
                    Scripts
                  </TableCell>
                  <TableCell>
                    Active
                  </TableCell>
                  <TableCell align="right">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hosts.sort((a, b) => a.hostname.localeCompare(b.hostname)).map((host) => (
                  <TableRow hover key={host.ID}>
                    <TableCell>
                        <Typography align="left" color="textPrimary" variant="subtitle2">
                          {host.hostname}
                        </Typography>
                        {user.role === 'rdpt_admin' ? (
                          <Typography align="center" color="textSecondary" variant="subtitle2">
                            {host.ID}
                          </Typography>
                        ) : '' }
                    </TableCell>
                    <TableCell>
                      {host.script_count}
                    </TableCell>
                    <TableCell onClick={() => handleChange(host.ID, host.status)}>
                        <FormControlLabel
                          control={(
                            <Switch color="primary" checked={host.status === 'active'} />
                          )}
                        />
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => handleDelete(host.ID)}>
                        <TrashIcon fontSize="small" />
                      </IconButton>
                      <IconButton component={RouterLink} to={`/dashboard/detail/host/${host.ID}`}>
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={hosts.length}
              onPageChange={() => { }}
              onRowsPerPageChange={() => { }}
              page={0}
              rowsPerPage={5}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Box>
        </Scrollbar>
      </Card>
  );
}

export default HostListTable;
