import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography
} from '@mui/material';
import useAuth from '../../hooks/useAuth';
import CogIcon from '../../icons/Cog';
import UserIcon from '../../icons/User';

function AccountPopover() {
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Box component={ButtonBase} onClick={handleOpen} ref={anchorRef} sx={{ alignItems: 'center', display: 'flex' }}>
        <Typography sx={{ mr: 2, fontSize: '.9rem' }}>{user.company}</Typography>
        <UserIcon fontSize="medium" />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{ sx: { width: 240 } }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {user.name}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2">
            {user.email}
          </Typography>
          {user.role === 'rdpt_admin'
            ? (
              <Typography color="red" variant="body2">
                RDPT ADMIN
              </Typography>
            )
            :
            ''}
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <MenuItem component={RouterLink} to="/dashboard/account">
            <ListItemIcon>
              <CogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography color="textPrimary" variant="subtitle2">
                  Settings
                </Typography>
              )}
            />
          </MenuItem>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button color="primary" fullWidth onClick={handleLogout} variant="outlined">
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default AccountPopover;
