import PropTypes from 'prop-types';
import { AppBar, Box, Hidden, IconButton, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/system';
import MenuIcon from '../../icons/Menu';
import AccountPopover from './AccountPopover';

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer + 100
}));

function DashboardNavbar(props) {
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarMobileOpen}>
            <MenuIcon fontSize="small" />
          </IconButton>
        </Hidden>
        <Hidden lgDown>
          <Box display="flex" alightItems="left">
            <img src="/static/logo.png" alt="redpoint logo" width="40" />
            <Typography sx={{
              ml: 1,
              color: '#FFF',
              fontSize: '1.5rem',
              fontFamily: 'Raleway, arial, sans-serif',
              letterSpacing: '0.25em' }}
            >
              SURVEYOR
            </Typography>
            <Typography sx={{
              ml: 2,
              mt: 1,
              color: '#FFF',
              fontSize: '1rem',
              fontStyle: 'italic',
              fontFamily: 'Raleway, arial, sans-serif',
              letterSpacing: '0.05em' }}
            >
              by
              <Typography sx={{ color: 'red', display: 'inline' }}> Redpoint</Typography>
              Labs
            </Typography>
          </Box>
        </Hidden>
        <Box sx={{ flexGrow: 1, ml: 2 }} />
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
}

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
