import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import Label from '../../Label';
import ArrowRightIcon from '../../../icons/ArrowRight';
import SearchIcon from '../../../icons/Search';
import Scrollbar from '../../Scrollbar';

const tabs = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Has Protected Fields',
    value: 'hasProtectFields'
  },
  {
    label: 'Has Forms',
    value: 'hasForms'
  },
  {
    label: 'Has Cookies',
    value: 'hasCookies'
  },
  {
    label: 'Has Findings',
    value: 'hasFindings'
  }
];

const sortOptions = [
  {
    label: 'Last Seen (newest)',
    value: 'modifiedAt|desc'
  },
  {
    label: 'Last Seen (oldest)',
    value: 'modifiedAt|asc'
  },
  {
    label: 'First Seen (newest)',
    value: 'createdAt|desc'
  },
  {
    label: 'First Seen (oldest)',
    value: 'createdAt|asc'
  },
  {
    label: 'Scores (highest)',
    value: 'score|desc'
  },
  {
    label: 'Scores (lowest)',
    value: 'score|asc'
  },
  {
    label: 'Findings (highest)',
    value: 'findings_count|desc'
  },
  {
    label: 'Findings (lowest)',
    value: 'findings_count|asc'
  }
];

const applyFilters = (urls, query, filters) => urls
  .filter((url) => {
    let matches = true;
    if (query) {
      const properties = ['full_url', 'hostname', 'ID'];
      let containsQuery = false;
      properties.forEach((property) => {
        if (url[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && url[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });

const applyPagination = (urls, page, limit) => urls
  .slice(page * limit, page * limit + limit);

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy) => (order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy));

const applySort = (urls, sort) => {
  const [orderBy, order] = sort.split('|');
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = urls.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    // @ts-ignore
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    // @ts-ignore
    return a[1] - b[1];
  });

  // @ts-ignore
  return stabilizedThis.map((el) => el[0]);
};

function PagesListTable(props) {
  const { user } = useAuth();
  const { urls, ...other } = props;
  const [currentTab, setCurrentTab] = useState('all');
  const [selectedUrls, setSelectedUrls] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState(sortOptions[0].value);
  const [filters, setFilters] = useState({
    hasProtectFields: null,
    hasForms: null,
    hasCookies: null,
    hasFindings: null
  });

  const handleTabsChange = (event, value) => {
    const updatedFilters = {
      ...filters,
      hasProtectFields: null,
      hasForms: null,
      hasCookies: null,
      hasFindings: null
    };

    if (value !== 'all') {
      updatedFilters[value] = true;
    }

    setFilters(updatedFilters);
    setSelectedUrls([]);
    setCurrentTab(value);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleSelectAllUrls = (event) => {
    setSelectedUrls(event.target.checked
      ? urls.map((url) => url.ID)
      : []);
  };

  // const handleSelectOneUrl = (event, urlId) => {
  //   if (!selectedUrls.includes(urlId)) {
  //     setSelectedUrls((prevSelected) => [...prevSelected, urlId]);
  //   } else {
  //     setSelectedUrls((prevSelected) => prevSelected.filter((id) => id !== urlId));
  //   }
  // };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const filteredUrls = applyFilters(urls, query, filters);
  const sortedUrls = applySort(filteredUrls, sort);
  const paginatedUrls = applyPagination(sortedUrls, page, limit);
  const enableBulkActions = selectedUrls.length > 0;
  const selectedSomeUrls = selectedUrls.length > 0
    && selectedUrls.length < urls.length;
  const selectedAllUrls = selectedUrls.length === urls.length;

  return (
    <Card {...other}>
      <Tabs
        indicatorColor="primary"
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="primary"
        value={currentTab}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 500
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              )
            }}
            onChange={handleQueryChange}
            placeholder="Search urls"
            value={query}
            variant="outlined"
          />
        </Box>
        <Box
          sx={{
            m: 1,
            width: 240
          }}
        >
          <TextField
            label="Sort By"
            name="sort"
            onChange={handleSortChange}
            select
            SelectProps={{ native: true }}
            value={sort}
            variant="outlined"
          >
            {sortOptions.map((option) => (
              <option
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </TextField>
        </Box>
      </Box>
      {enableBulkActions && (
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              backgroundColor: 'background.paper',
              mt: '6px',
              position: 'absolute',
              px: '4px',
              width: '100%',
              zIndex: 2
            }}
          >
            <Checkbox
              checked={selectedAllUrls}
              color="primary"
              indeterminate={selectedSomeUrls}
              onChange={handleSelectAllUrls}
            />
            <Button
              color="primary"
              sx={{ ml: 2 }}
              variant="outlined"
            >
              Delete
            </Button>
            <Button
              color="primary"
              sx={{ ml: 2 }}
              variant="outlined"
            >
              Edit
            </Button>
          </Box>
        </Box>
      )}
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllUrls}
                    color="primary"
                    indeterminate={selectedSomeUrls}
                    onChange={handleSelectAllUrls}
                  />
                </TableCell> */}
                <TableCell>
                  Last Seen
                </TableCell>
                <TableCell>
                  First Seen
                </TableCell>
                <TableCell>
                  Page
                </TableCell>
                <TableCell>
                  Details
                </TableCell>
                {user.role !== 'rdpt_admin'
                  ? (
                    <TableCell>
                      Findings
                    </TableCell>
                  ) :
                  ''}
                <TableCell>
                  Score
                </TableCell>
                <TableCell align="right">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedUrls.map((url) => {
                const isUrlSelected = selectedUrls.includes(url.ID);

                return (
                  <TableRow
                    hover
                    key={url.ID}
                    selected={isUrlSelected}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        checked={isUrlSelected}
                        color="primary"
                        onChange={(event) => handleSelectOneUrl(event, url.ID)}
                        value={isUrlSelected}
                      />
                    </TableCell> */}
                    <TableCell width={100}>
                      <Box sx={{ p: 1 }}>
                        <Typography
                          align="center"
                          color="textPrimary"
                          variant="subtitle2"
                        >
                          {format(new Date(`${url.modified_at.replace(' ', 'T')}+0000`), 'LLL d').toUpperCase()}
                        </Typography>
                        <Typography
                          align="center"
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {format(new Date(`${url.modified_at.replace(' ', 'T')}+0000`), 'HH:mm:ss')}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell width={100}>
                      <Box sx={{ p: 1 }}>
                        <Typography
                          align="center"
                          color="textPrimary"
                          variant="subtitle2"
                        >
                          {format(new Date(`${url.created_at.replace(' ', 'T')}+0000`), 'LLL d').toUpperCase()}
                        </Typography>
                        <Typography
                          align="center"
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {format(new Date(`${url.created_at.replace(' ', 'T')}+0000`), 'HH:mm:ss')}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Box sx={{ ml: 1 }}>
                          <Link
                            color="inherit"
                            variant="subtitle2"
                          >
                            {url.full_url}
                          </Link>
                          <Typography
                            color="textSecondary"
                            variant="body2"
                          >
                            {url.hostname}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {url.hasProtectFields
                        ? (
                          <Label
                            color="success"
                            variant="outlined"
                            sx={{
                              m: 1
                            }}
                          >
                            Protected Fields
                          </Label>
                        )
                        : (
                          ''
                        )}
                      {url.hasForms
                        ? (
                          <Label
                            color="info"
                            variant="outlined"
                            sx={{
                              m: 1
                            }}
                          >
                            Forms
                          </Label>
                        )
                        : (
                          ''
                        )}
                      {url.hasCookies
                        ? (
                          <Label
                            color="warning"
                            variant="outlined"
                            sx={{
                              m: 1
                            }}
                          >
                            Cookies
                          </Label>
                        )
                        : (
                          ''
                        )}
                      {/* {url.hasFindings
                        ? (
                          <Label
                            color="secondary"
                            variant="outlined"
                            sx={{
                              m: 1
                            }}
                          >
                            Findings
                          </Label>
                        )
                        : (
                          ''
                        )} */}
                    </TableCell>
                    {user.role !== 'rdpt_admin'
                      ? (
                        <TableCell>
                          <Typography
                            align="center"
                            color="textPrimary"
                            variant="subtitle2"
                          >
                            {url.findings_count}
                          </Typography>
                        </TableCell>
                      ) :
                      ''}
                    <TableCell>
                      <Typography
                        align="center"
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {url.score}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={`/dashboard/detail/page/${url.ID}`}
                      >
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={filteredUrls.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
}

PagesListTable.propTypes = {
  urls: PropTypes.array.isRequired
};

export default PagesListTable;
