// import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { API } from 'aws-amplify';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ReactLoading from 'react-loading';
import { useState, useEffect, useCallback } from 'react';
// import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

const apiName = 'surveyor-API';

function ScanCode(props) {
  // const { user } = useAuth();
  const { scanId } = useParams();
  const isMountedRef = useIsMountedRef();
  const [code, setCode] = useState('');
  const type = 'spinningBubbles';
  const color = '#688eff';
  const path = (`/scan/template/${scanId}`);
  const getCode = useCallback(async () => {
    try {
      const response = await API.get(apiName, path);
      console.log(response.template);
      if (isMountedRef.current) {
        setCode(response.template);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getCode();
  }, [getCode]);

  if (!code) {
    return (
      <ReactLoading
        type={type}
        color={color}
        height={1055}
        width={100}
      />
    );
  }
  return (
    <SyntaxHighlighter
      {...props}
      language="javascript"
      style={vscDarkPlus}
      wrapLongLines
      showInlineLineNumbers
    >
      {code}
    </SyntaxHighlighter>
  );
}

ScanCode.propTypes = {
  // @ts-ignore
  scan: PropTypes.object.isRequired
};

export default ScanCode;
