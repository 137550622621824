import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Amplify, { Auth } from 'aws-amplify';
import { amplifyConfig } from '../config';

Amplify.configure(amplifyConfig);

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state) => ({ ...state }),
  VERIFY_CODE: (state) => ({ ...state }),
  RESEND_CODE: (state) => ({ ...state }),
  PASSWORD_RECOVERY: (state) => ({ ...state }),
  PASSWORD_RESET: (state) => ({ ...state }),
  SET_NEW_PASSWORD: (state) => ({ ...state })
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'Amplify',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve(),
  update: () => Promise.resolve(),
  setNewPassword: () => Promise.resolve()
});

export function AuthProvider(props) {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();

        // Here you should extract the complete user profile to make it
        // available in your entire app.
        // The auth state only provides basic information.

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: {
              id: user.attributes.sub,
              email: user.attributes.email,
              firstName: user.attributes['custom:first_name'],
              lastName: user.attributes['custom:last_name'],
              name: `${user.attributes['custom:first_name']} ${user.attributes['custom:last_name']}`,
              company: user.attributes['custom:company'],
              role: user.attributes['custom:role'],
              status: user.attributes['custom:status']
            }
          }
        });
      } catch (error) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const user = await Auth.signIn(email, password);
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      console.log('NEW_PASSWORD_REQUIRED');
      const error = { code: 'NewPasswordRequired', user };
      throw error;
    }

    // if (user.challengeName) {
    //   console.error(`Unable to login, because challenge "${user.challengeName}" is mandated and we did not handle this case.`);
    //   return;
    // }

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          id: user.attributes.sub,
          email: user.attributes.email,
          firstName: user.attributes['custom:first_name'],
          lastName: user.attributes['custom:last_name'],
          name: `${user.attributes['custom:first_name']} ${user.attributes['custom:last_name']}`,
          company: user.attributes['custom:company']
        }
      }
    });
  };

  const setNewPassword = async (user, password) => {
    await Auth.completeNewPassword(user, password, {});
    dispatch({
      type: 'SET_NEW_PASSWORD',
      payload: {
        user,
        password
      }
    });
  };

  const logout = async () => {
    await Auth.signOut();
    dispatch({
      type: 'LOGOUT'
    });
  };

  const register = async (email, password, firstName, lastName, company, host) => {
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        'custom:first_name': firstName,
        'custom:last_name': lastName,
        'custom:company': company,
        'custom:host': host
      }
    });
    dispatch({
      type: 'REGISTER'
    });
  };

  const update = async (email, firstName, lastName, company) => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(
      user,
      {
        email,
        'custom:first_name': firstName,
        'custom:last_name': lastName,
        'custom:company': company
      }
    );
    dispatch({
      type: 'UPDATE'
    });
  };

  const verifyCode = async (username, code) => {
    await Auth.confirmSignUp(username, code);
    dispatch({
      type: 'VERIFY_CODE'
    });
  };

  const resendCode = async (username) => {
    await Auth.resendSignUp(username);
    dispatch({
      type: 'RESEND_CODE'
    });
  };

  const passwordRecovery = async (username) => {
    await Auth.forgotPassword(username);
    dispatch({
      type: 'PASSWORD_RECOVERY'
    });
  };

  const passwordReset = async (username, code, newPassword) => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    dispatch({
      type: 'PASSWORD_RESET'
    });
  };
  /* eslint-disable */
  /*
    const authProviderVal = useMemo(() => ({
      ...state,
      platform: 'Amplify',
      login,
      logout,
      register,
      verifyCode,
      resendCode,
      passwordRecovery,
      passwordReset,
      update,
      setNewPassword
    }), []);
  */
  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Amplify',
        login,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset,
        update,
        setNewPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
  /* eslint-enable */

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
